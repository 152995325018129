import { bindable, inject, Loader, useView } from 'aurelia-framework';
import { AppContainer }                      from 'resources/services/app-container';
import { FormSelect2 }                       from 'resources/elements/aurelia-form/components/form-select2';
import { DiplomaClassificationsRepository }  from 'modules/legislation/management/diploma-classifications/services/repository';

@inject(AppContainer, DiplomaClassificationsRepository, Loader)
@useView('resources/elements/aurelia-form/components/form-select2.html')
export class FormDiplomaClassificationsSelect extends FormSelect2 {

    @bindable selectedOption;

    /**
     * Constructor
     *
     * @param appContainer
     * @param diplomaClassificationsRepository
     * @param loader
     */
    constructor(appContainer, diplomaClassificationsRepository, loader) {
        super(appContainer, loader);

        this.diplomaClassificationsRepository = diplomaClassificationsRepository;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        // force use of active user as remote source
        model.element.remoteSource = !(model.element.remoteSource instanceof Function)
            ? this.diplomaClassificationsRepository.active.bind(this.diplomaClassificationsRepository)
            : model.element.remoteSource;

        // process results
        model.element.processResults = (item) => {
            item.name = `${item.order} - ${item.ancestors_and_self.join(' » ')}`;

            return item;
        };

        // escape html from select2
        model.element.settings = {
            escapeMarkup: (markup) => markup,
        };

        return super.activate(model);
    }

}
