import { bindable, bindingMode } from 'aurelia-framework';

export class FormFields {

    @bindable schema;
    @bindable({defaultBindingMode: bindingMode.twoWay}) formId;
    @bindable({defaultBindingMode: bindingMode.twoWay}) model;

    /**
     * Handles attached event
     */
    attached() {
        if (typeof this.model !== 'object') {
            console.log(this.model);
            console.log('Model is not an object');
        }
    }

}
