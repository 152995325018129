import { bindable, bindingMode, inject } from 'aurelia-framework';
import { AppContainer }                  from 'resources/services/app-container';
import { DangerAlertMessage }            from 'resources/services/danger-alert-message';
import { SuccessAlertMessage }           from 'resources/services/success-alert-message';
import { WarningAlertMessage }           from 'resources/services/warning-alert-message';

@inject(AppContainer)
export class FormAlert {

    @bindable({defaultBindingMode: bindingMode.twoWay}) alert;

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        this.appContainer = appContainer;

        this.appContainer
            .bindingEngine
            .propertyObserver(this, 'alert')
            .subscribe((newAlert, oldAlert) => {
                if ((newAlert instanceof SuccessAlertMessage || newAlert instanceof DangerAlertMessage || newAlert instanceof WarningAlertMessage) && newAlert.scrollTop) {
                    $('html, body, .modal').animate({
                        scrollTop: 0,
                    }, 500);
                }
            });
    }

    /**
     * Hides alert
     */
    hide() {
        this.alert.visible = false;
    }

}
