import { customElement, inject } from 'aurelia-framework';
import { AppContainer }          from 'resources/services/app-container';
import { BaseComponent }         from 'resources/elements/aurelia-form/components/base-component';
import 'bootstrap-tokenfield';

@inject(AppContainer)
@customElement('form-tags')
export class FormTags extends BaseComponent {

    defaultSettings = {
        beautify: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        super(appContainer);
    }

    /**
     * Creates element
     */
    createElement() {
        return this.simplePromise(() => {

            let htmlElement = $('#' + this.modelElementId);
            let settings    = $.extend({}, this.defaultSettings, this.model.element.settings);

            $(htmlElement)
                .tokenfield(settings)
                .on('tokenfield:createtoken', (event) => {
                    let existingTokens = $(htmlElement).tokenfield('getTokens');

                    $.each(existingTokens, (index, token) => {
                        if (token.value === event.attrs.value) {
                            event.preventDefault();
                        }
                    });
                })
                .on('tokenfield:createdtoken', (event) => {
                    this.model.value = $(htmlElement).tokenfield('getTokensList');
                })
                .on('tokenfield:removedtoken', (event) => {
                    this.model.value = $(htmlElement).tokenfield('getTokensList');
                });

            $(htmlElement).tokenfield(this.model.element.attributes.disabled ? 'disable' : 'enable');
        });
    }

    /**
     * Destroys element
     */
    destroyElement() {
        return this.simplePromise(() => {
            $('#' + this.modelElementId).tokenfield('destroy');
        });
    }

    /**
     * Subscribes event listeners
     */
    subscribeEventListeners() {
        // subscribes `form-element-options-updated` event
        this.eventListeners.push(
            this.appContainer.eventAggregator.subscribe('form-element-options-updated', (elementId) => {
                if (!elementId || elementId === this.modelElementId) {
                    // destroy & recreate element
                    this.destroyElement().then(() => setTimeout(() => this.createElement(), 0));
                }
            }),
        );
    }

}
