import { bindable, customElement, inject } from 'aurelia-framework';
import { AppContainer }                    from 'resources/services/app-container';
import { BaseComponent }                   from 'resources/elements/aurelia-form/components/base-component';
import { createTree }                      from 'jquery.fancytree';

import 'jquery.fancytree/dist/modules/jquery.fancytree.edit';
import 'jquery.fancytree/dist/modules/jquery.fancytree.filter';
import 'jquery.fancytree/dist/modules/jquery.fancytree.persist';

@inject(AppContainer)
@customElement('form-fancy-tree')
export class FormFancytree extends BaseComponent {

    tree;

    firstCreation = true;

    defaultSettings = {
        checkbox:   true,
        selectMode: 2,
        select:     this.treeNodeSelected.bind(this),
        extensions: [],
    };

    @bindable customSettings;
    @bindable filterText;

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        super(appContainer);
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.model          = model;
        this.modelElementId = this.model.element.id || this.model.element.key;
        this.customSettings = this.model.element.settings ? this.model.element.settings : {};

        // this instance in order to be possible to access it from outside
        this.model.element.instance = this;

        return this.fetchData();
    }

    /**
     * Handles attached event
     */
    createElement() {
        return this.simplePromise(() => {
            let settings = this.handleSettings();

            this.tree = createTree(`#${this.modelElementId}`, settings);

            if (this.customSettings.editable === true) {
                $(this.tree)
                    .on('nodeCommand', this.nodeCommandCallback())
                    .on('keydown', this.keydownCallback());
            }

            this.selectInitialNodes();

            this.firstCreation = false;
        });
    }

    /**
     * Subscribes event listeners
     */
    subscribeEventListeners() {
        super.subscribeEventListeners();

        // subscribes `form-element-options-updated` event
        this.eventListeners.push(
            this.appContainer.eventAggregator.subscribe('form-element-options-updated', (elementId) => {
                if (!elementId || elementId === this.modelElementId) {
                    // destroy & recreate element
                    this.destroyElement().then(() => this.createElement());
                }
            }),
        );
    }

    /**
     * Subscribes observers
     */
    subscribeObservers() {
        // subscribes `model.value` property change
        this.observers.push(
            this.appContainer
                .bindingEngine
                .collectionObserver(this.model.value)
                .subscribe(() => this.selectInitialNodes()),
        );
    }

    /**
     * Selects initial nodes
     */
    selectInitialNodes() {
        if (this.firstCreation) {
            let modelValues = this.model.value.slice(0);

            $.ui.fancytree.getTree(this.tree).visit((node) => {
                let nodeKey  = parseInt(node.key, 10);
                let selected = modelValues.indexOf(nodeKey) >= 0;

                node.setSelected(selected);

                if (selected && this.customSettings.disableSelected) {
                    node.unselectable       = true;
                    node.unselectableStatus = true;
                }

                if ((selected && this.customSettings.disableSelected) || (this.model.element.attributes && this.model.element.attributes.disabled)) {
                    node.unselectable       = true;
                    node.unselectableStatus = true;
                }
            });
        }
    }

    /**
     * Fetches data from remote source
     *
     * @returns {*}
     */
    fetchData() {
        let parameters = {};

        if (this.model.element.remoteSourceParameters instanceof Function) {
            parameters = this.model.element.remoteSourceParameters();

            if (isEmpty(parameters)) {
                return Promise.resolve([]).then((response) => this.customSettings.source = response);
            }
        }

        return this.model.element.remoteSource(parameters).then((response) => this.customSettings.source = response);
    }

    /**
     * Handles settings
     */
    handleSettings() {
        let settings = $.extend({}, this.defaultSettings, this.customSettings);

        if (settings.editable === true) {
            settings.extensions.push('edit');

            settings.edit = {
                close: (event, data) => this.handleEdition(data),
            };
        }

        if (settings.filterable === true) {
            settings.extensions.push('filter');

            settings.quicksearch = true;

            settings.filter = {
                autoApply:           true,   // Re-apply last filter if lazy data is loaded
                autoExpand:          true,   // Expand all branches that contain matches while filtered
                counter:             false,  // Show a badge with number of matching child nodes near parent icons
                fuzzy:               false,  // Match single characters in order, e.g. 'fb' will match 'FooBar'
                hideExpandedCounter: true,   // Hide counter badge if parent is expanded
                hideExpanders:       true,   // Hide expanders if all child nodes are hidden by filter
                highlight:           true,   // Highlight matches by wrapping inside <mark> tags
                leavesOnly:          false,  // Match end nodes only
                nodata:              true,   // Display a 'no data' status node if result is empty
                mode:                'hide', // Grayout unmatched nodes (pass "hide" to remove unmatched node instead)
            };
        }

        if (settings.disable === true) {
            this.disable();
        }

        return settings;
    }

    /**
     * Handles node edition
     *
     * @param data
     */
    handleEdition(data) {
        let node = data.node;

        if (typeof node !== 'undefined' && node !== null) {
            let parent_id = node.parent.key;

            let nodeData = {
                name:      node.title,
                parent_id: parseInt(parent_id, 10) ? parent_id : null,
                status_id: 1,
            };

            if (data.save && data.isNew) {
                this.customSettings.repository.create(nodeData).then((response) => {
                    if (response.status === true) {
                        node.key = response.model.id;
                    }
                });
            }

            if (data.save && !data.isNew) {
                this.customSettings.repository.update(node.key, nodeData);
            }
        }
    }

    /**
     * Handles tree node select event
     *
     * @param event
     * @param data
     */
    treeNodeSelected(event, data) {
        if (this.customSettings.treeNodeSelected instanceof Function) {
            this.customSettings.treeNodeSelected(event, data);
        }

        let selectedNodes = $.map(data.tree.getSelectedNodes(), (node) => parseInt(node.key, 10));

        this.model.value.splice(0, this.model.value.length, ...selectedNodes);
    }

    /**
     * Collapses all nodes
     */
    collapseAll() {
        this.tree.getRootNode().visit((node) => node.setExpanded(false));
    }

    /**
     * Expands all nodes
     */
    expandAll() {
        this.tree.getRootNode().visit((node) => node.setExpanded(true));
    }

    /**
     * Adds a new child
     */
    addChild() {
        $(this.tree).trigger('nodeCommand', {cmd: 'addChild'});
    }

    /**
     * Adds a new sibling
     */
    addSibling() {
        $(this.tree).trigger('nodeCommand', {cmd: 'addSibling'});
    }

    /**
     * Edits a node
     */
    editNode() {
        $(this.tree).trigger('nodeCommand', {cmd: 'editNode'});
    }

    /**
     * Removes a node
     *
     * @param node
     */
    removeNode(node) {
        console.error('Not implemented yet');
    }

    /**
     * Returns node command callback
     *
     * @returns {function(*, *)}
     */
    nodeCommandCallback() {
        return (event, data) => {
            let node = $.ui.fancytree.getTree(this.tree).getActiveNode();

            if (typeof node !== 'undefined' && node !== null) {
                switch (data.cmd) {
                    case 'addChild':
                        node.editCreateNode('child', '');
                        break;
                    case 'addSibling':
                        node.editCreateNode('after', '');
                        break;
                    case 'editNode':
                        node.editStart();
                        break;
                    case 'removeNode':
                        this.removeNode(node);
                        break;
                    default:
                        alert('Unhandled command: ' + data.cmd);
                        return;
                }
            }
        };
    }

    /**
     * Returns node command callback
     *
     * @returns {function(*, *)}
     */
    keydownCallback() {
        return (event) => {
            let command = null;

            switch ($.ui.fancytree.eventToString(event)) {
                case 'ctrl+shift+m':
                case 'meta+shift+m': // mac: cmd+shift+m
                    command = 'addChild';
                    break;
                case 'ctrl+m':
                case 'meta+m': // mac: cmd+m
                    command = 'addSibling';
                    break;
                case 'del':
                    command = 'removeNode';
                    break;
                default:
                    return;
            }

            if (command) {
                $(this.tree).trigger('nodeCommand', {cmd: command});
                // e.preventDefault();
                // e.stopPropagation();
                return false;
            }
        };
    }

    /**
     * Deselects node by key
     */
    deselectNodeByKey(key) {
        let node = this.tree.getNodeByKey(key);

        node.setSelected(false);
        node.setActive(false);
    }

    /**
     * Handles filter value
     */
    filterTextChanged() {
        this.filterText && this.filterText.length
            ? this.filterNodes()
            : this.clearFilter();
    }

    /**
     * Filters tree nodes
     */
    filterNodes() {
        this.tree.filterNodes(this.filterText);
    }

    /**
     * Clears filter
     */
    clearFilter() {
        this.filterText = null;

        this.tree.clearFilter();
    }

    /**
     * Disables fancy tree
     */
    disable() {
        $('#' + this.modelElementId).fancytree('disable');
    }

}
