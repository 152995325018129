import { BindingEngine, inject } from 'aurelia-framework';

@inject(BindingEngine, Element)
export class FormAttributesCustomAttribute {

    /**
     * Observers list
     *
     * @type {Array}
     */
    observers = [];

    /**
     * Constructor
     *
     * @param bindingEngine
     * @param element
     */
    constructor(bindingEngine, element) {
        this.bindingEngine = bindingEngine;
        this.element       = element;
    }

    /**
     * Handles attached event
     */
    attached() {
        for (let key in this.value) {
            this.observers.push(
                this.bindingEngine
                    .propertyObserver(this.value, key)
                    .subscribe(this.objectValueChanged.bind(this)));
        }

        this.objectValueChanged();
    }

    /**
     * Handles detached event
     */
    detached() {
        while (this.observers.length) {
            this.observers.pop().dispose();
        }
    }

    objectValueChanged(newValue, oldValue) {
        $(this.element).attr(this.value || {});
    }
}
