import { customElement, inject } from 'aurelia-framework';
import { AppContainer }          from 'resources/services/app-container';
import { AuthService }           from 'aurelia-authentication';
import { BaseComponent }         from 'resources/elements/aurelia-form/components/base-component';
import Dropzone                  from 'assets/js/plugins/uploaders/dropzone.min';
import environment               from 'environment';

@inject(AppContainer, AuthService)
@customElement('form-file-dropzone')
export class FormFile extends BaseComponent {

    dropzoneElementId = null;
    dropzoneElement   = null;

    /**
     * Constructor
     *
     * @param appContainer
     * @param authService
     */
    constructor(appContainer, authService) {
        super(appContainer);

        this.authService = authService;

        this.dropzoneElementId = 'dropzone_container_' + Math.floor(Math.random() * (65536 - 1 + 1) + 1);
    }

    /**
     * Creates element
     */
    createElement() {
        return this.simplePromise(() => {
            let self     = this;
            let token    = this.authService.getAccessToken();
            let selector = '#' + this.dropzoneElementId;

            this.dropzoneElement = new Dropzone(selector, {
                maxFiles:           1,
                maxFilesize:        environment.uploadMaxFilesize, // MB
                addRemoveLinks:     true,
                dictDefaultMessage: self.appContainer.i18n.tr('text.dropzone.dict-default-message'),
                init:               function () {
                    this.on('addedfile', function (file) {
                        if (this.fileTracker) {
                            this.removeFile(this.fileTracker);
                        }

                        this.fileTracker = file;
                    });

                    this.on('removedfile', function (file) {
                        self.model.value = null;
                    });
                },
                url:                self.model.element.url,
                headers:            {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization':    `Bearer ${token}`,
                },
                success:            (file, response) => {
                    if (response.status === true) {
                        self.model.value = response.file.id;
                    }
                },
            });

            // prepopulate dropzone with file if this is an edit operation
            self.mockFileChanged();
        });
    }

    /**
     * Subscribes observers
     */
    subscribeObservers() {
        // subscribes model.value property change
        this.observers.push(
            this.appContainer
                .bindingEngine
                .propertyObserver(this.model, 'value')
                .subscribe(this.modelValueChanged.bind(this)),
        );

        this.observers.push(
            this.appContainer
                .bindingEngine
                .propertyObserver(this.model.element, 'mockFile')
                .subscribe(this.mockFileChanged.bind(this)),
        );
    }

    /**
     * Destroys dropzone element
     */
    destroyElement() {
        return this.simplePromise(() => {
            if (this.dropzoneElement) {
                this.dropzoneElement.destroy();
                this.dropzoneElement = null;
            }
        });
    }

    /**
     * Fires on model value change
     */
    modelValueChanged(nv, ov) {
        if (!isNumeric(nv)) {
            this.removeFiles();
        }
    }

    /**
     * Fires on model element edit value change
     */
    mockFileChanged(nv, ov) {
        if (this.model.element.mockFile) {
            let mockFile = {
                name: this.model.element.mockFile.display_name,
                size: this.model.element.mockFile.size,
                type: this.model.element.mockFile.mime_type,
            };

            this.dropzoneElement.emit('addedfile', mockFile);
            this.dropzoneElement.emit('complete', mockFile);
            // this.dropzoneElement.emit("thumbnail", mockFile, this.model.element.url);
            // this.dropzoneElement.createThumbnailFromUrl(mockFile, this.model.element.url);
        }
    }

    /**
     * Destroys dropzone element
     */
    removeFiles() {
        if (this.dropzoneElement) {
            this.dropzoneElement.removeAllFiles();
        }
    }
}
