import { bindable, inject, Loader, useView } from 'aurelia-framework';
import { AppContainer }                      from 'resources/services/app-container';
import { FormSelect2 }                       from 'resources/elements/aurelia-form/components/form-select2';
import { IconsRepository }                   from 'modules/administration/icons/services/repository';

@inject(AppContainer, Loader, IconsRepository)
@useView('resources/elements/aurelia-form/components/form-select2.html')
export class FormSelect2Icons extends FormSelect2 {

    @bindable selectedOption;

    /**
     * Constructor
     *
     * @param appContainer
     * @param loader
     * @param iconsRepository
     */
    constructor(appContainer, loader, iconsRepository) {
        super(appContainer, loader);

        this.iconsRepository = iconsRepository;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        // force use of active user as remote source
        model.element.remoteSource = this.iconsRepository.active.bind(this.iconsRepository);

        // process results
        model.element.processResults = (element) => {
            return {id: element.icon_class, name: `<i class="${element.icon_class}"></i>${element.name}`};
        };

        // escape html from select2
        model.element.settings = {
            escapeMarkup: (markup) => markup,
        };

        return super.activate(model);
    }

}
