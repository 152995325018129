import { bindable, computedFrom, inject, useView } from 'aurelia-framework';
import { BaseComponent }                           from 'resources/elements/aurelia-form/components/base-component';
import { AppContainer }                            from 'resources/services/app-container';
import React                                       from 'react';
import ReactDOM                                    from 'react-dom';
import 'moment/locale/pt';

@inject(AppContainer, React, ReactDOM)
@useView('resources/elements/aurelia-form/components/form-material-ui-picker.html')
export class FormMaterialUiPicker extends BaseComponent {

    @bindable hasFocus = false;

    format = null;

    /**
     * Returns mui picker container id
     *
     * @returns {HTMLElement}
     */
    get materialUiPickerContainer() {
        return document.getElementById(this.materialUiPickerContainerId);
    }

    /**
     * Returns mui picker container id
     *
     * @returns {string}
     */
    @computedFrom('modelElementId')
    get materialUiPickerContainerId() {
        return `${this.modelElementId}-material-ui-picker-container`;
    }

    /**
     * Opens picker
     */
    openPicker() {
        throw new Error('openPicker() method must be implemented in child classes.');
    }

    /**
     * Updates model value
     *
     * @param value
     */
    updateModelValue(value) {
        this.model.value = isEmpty(value) ? value : value.format(this.format);
    }

    /**
     * Handles key press
     *
     * @param $event
     *
     * @returns {boolean}
     */
    keyDown($event) {
        $event.stopPropagation();

        if ($event.code === 'Enter' || $event.code === 'Space') {
            this.openPicker();
        }

        return $event.code === 'Tab';
    }

}
