import { bindable, inject, Loader, useView } from 'aurelia-framework';
import { AppContainer }                      from 'resources/services/app-container';
import { FormSelect2 }                       from 'resources/elements/aurelia-form/components/form-select2';
import { UsersRepository }                   from 'modules/administration/users/services/repository';

@inject(AppContainer, UsersRepository, Loader)
@useView('resources/elements/aurelia-form/components/form-select2.html')
export class FormUserSelect extends FormSelect2 {

    @bindable selectedOption;

    /**
     * Constructor
     *
     * @param appContainer
     * @param usersRepository
     * @param loader
     */
    constructor(appContainer, usersRepository, loader) {
        super(appContainer, loader);

        this.usersRepository = usersRepository;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        // force use of active user as remote source
        model.element.remoteSource = this.usersRepository.active.bind(this.usersRepository);

        return super.activate(model);
    }

}
