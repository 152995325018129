import { bindable, inject }           from 'aurelia-framework';
import { AppContainer }               from 'resources/services/app-container';
import { BaseComponent }              from 'resources/elements/aurelia-form/components/base-component';
import { remove as removeDiacritics } from 'diacritics';

@inject(AppContainer)
export class FormDuallistbox extends BaseComponent {

    settings = {
        collapsible: false,
    };

    expanded = true;
    disabled = false;

    itemsToSelect   = [];
    itemsToDeselect = [];

    listOfItemsToSelect = [];
    listOfSelectedItems = [];

    filteredListOfItemsToSelect = [];
    filteredListOfSelectedItems = [];

    @bindable itemsToSelectFilter = '';
    @bindable selectedItemsFilter = '';

    /**
     * Toggle expanded attribute
     */
    toggleExpanded() {
        this.expanded = !this.expanded;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.model          = model;
        this.modelElementId = this.model.element.id || this.model.element.key;

        this.model.element.options    = this.model.element.options || [];
        this.model.element.attributes = this.model.element.attributes || {};

        this.disabled = this.model.element.attributes.disabled === true;

        // merges user defined settings with the component default settings
        this.settings = $.extend({}, this.settings, this.model.element.settings);

        // this instance in order to be possible to access it from outside
        this.model.element.instance = this;

        return this.fetchData();
    }

    /**
     * Fetches data from remote source
     *
     * @returns {*}
     */
    fetchData() {
        let parameters = {};

        if (this.model.element.remoteSourceParameters instanceof Function) {
            parameters = this.model.element.remoteSourceParameters();

            if (!parameters) {
                return Promise.resolve([]);
            }
        }

        return this.model.element.remoteSource(parameters)
            .then((response) => {
                if (this.model.element.processResults instanceof Function) {
                    response = response.map(item => this.model.element.processResults(item));
                }

                this.model.element.options.splice(0, this.model.element.options.length, ...response);

                return response;
            })
            .then((response) => {
                this.synchronizeLists();

                return response;
            });
    }

    /**
     * Override to prevent firing of the base component's subscribeObservers() function
     */
    subscribeObservers() {
        this.observers.push(
            this.appContainer
                .bindingEngine
                .collectionObserver(this.model.value)
                .subscribe(() => this.synchronizeLists()),
        );

        this.observers.push(
            this.appContainer
                .bindingEngine
                .propertyObserver(this.model.element.attributes, 'disabled')
                .subscribe((newValue, oldValue) => this.onDisabledChanged(newValue, oldValue)),
        );
    }

    /**
     * Synchronizes all lists
     */
    synchronizeLists() {
        const itemsToSelect = this.model.element.options.filter((option) => this.model.value.indexOf(option.id) === -1);
        const selectedItems = this.model.element.options.filter((option) => this.model.value.indexOf(option.id) !== -1);

        this.listOfItemsToSelect.splice(0, this.listOfItemsToSelect.length, ...itemsToSelect);
        this.listOfSelectedItems.splice(0, this.listOfSelectedItems.length, ...selectedItems);

        this.applyFilter(this.itemsToSelectFilter, this.listOfItemsToSelect, this.filteredListOfItemsToSelect);
        this.applyFilter(this.selectedItemsFilter, this.listOfSelectedItems, this.filteredListOfSelectedItems);

        return true;
    }

    /**
     * Recreates element
     *
     * @returns {Promise|Promise<number>}
     */
    recreateElement() {
        return this.simplePromise();
    }

    /**
     * Handle change of `disabled` property
     */
    onDisabledChanged(newValue, oldValue) {
        this.disabled = !!newValue;
    }

    /**
     * Move selected items
     */
    moveSelected() {
        const selectedItems = this.listOfSelectedItems.concat(this.itemsToSelect);

        this.model.value.splice(0, this.model.value.length, ...selectedItems.map(item => item.id));
    }

    /**
     * Move all items
     */
    moveAll() {
        const selectedItems = this.listOfSelectedItems.concat(this.listOfItemsToSelect);

        this.model.value.splice(0, this.model.value.length, ...selectedItems.map(item => item.id));
    }

    /**
     * Remove selected items
     */
    removeSelected() {
        const itemsToDeselect = this.itemsToDeselect.map(item => item.id);
        const selectedItems   = this.model.value.filter(option => itemsToDeselect.indexOf(option) === -1);

        this.model.value.splice(0, this.model.value.length, ...selectedItems);
    }

    /**
     * Remove all items
     */
    removeAll() {
        this.model.value.splice(0, this.model.value.length);
    }

    /**
     * Handles change of `items to select filter`
     */
    itemsToSelectFilterChanged() {
        this.applyFilter(this.itemsToSelectFilter, this.listOfItemsToSelect, this.filteredListOfItemsToSelect);
    }

    /**
     * Handles change of `selected items filter`
     */
    selectedItemsFilterChanged() {
        this.applyFilter(this.selectedItemsFilter, this.listOfSelectedItems, this.filteredListOfSelectedItems);
    }

    /**
     * Applies a filter
     *
     * @param filter
     * @param sourceList
     * @param targetList
     */
    applyFilter(filter, sourceList, targetList) {
        const filterText = removeDiacritics(filter).toLowerCase();

        const filteredOptions = sourceList.filter(option => removeDiacritics(option.name).toLowerCase().includes(filterText));

        targetList.splice(0, targetList.length, ...filteredOptions);
    }

    /**
     * Clears filter of items to select
     */
    clearItemsToSelectFilter() {
        this.itemsToSelectFilter = '';
    }

    /**
     * Clears filter of selected items
     */
    clearSelectedItemsFilter() {
        this.selectedItemsFilter = '';
    }

    /**
     * Returns selected items
     *
     * @returns {*}
     */
    selectedItems() {
        return this.model.element.options.filter(option => this.model.value.indexOf(option.id) !== -1);
    }

    /**
     * Returns an item by its id
     *
     * @returns {*}
     */
    item(id) {
        return this.model.element.options.find(option => option.id === id);
    }
}
