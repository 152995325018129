import { bindable, inject, Loader, useView } from 'aurelia-framework';
import { AppContainer }                      from 'resources/services/app-container';
import { FormSelect2 }                       from 'resources/elements/aurelia-form/components/form-select2';
import { DateRepository }                    from 'modules/administration/date/services/repository';

@inject(AppContainer, DateRepository, Loader)
@useView('resources/elements/aurelia-form/components/form-select2.html')
export class FormMonthsSelect2 extends FormSelect2 {

    @bindable selectedOption;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param loader
     */
    constructor(appContainer, repository, loader) {
        super(appContainer, loader);

        this.repository = repository;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        model.element.remoteSource = this.repository.months.bind(this.repository);

        return super.activate(model);
    }

}
