import { customElement, inject } from 'aurelia-framework';
import { AppContainer }          from 'resources/services/app-container';
import { BaseComponent }         from 'resources/elements/aurelia-form/components/base-component';

@inject(AppContainer)
@customElement('form-input')
export class FormInput extends BaseComponent {

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        super(appContainer);

        this.acceptableTypes = ['text', 'number', 'email', 'password'];
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        if (this.acceptableTypes.indexOf(model.element.type) === -1) {
            model.element.type = 'text';
        }

        super.activate(model);
    }
}
