import { bindable, containerless, customElement } from 'aurelia-framework';

@containerless()
@customElement('form-button')
export class FormButton {

    @bindable button;
    @bindable element;

    /**
     * Handles button click event
     *
     * @param event
     */
    buttonClick(event) {
        if (this.button.action instanceof Function) {
            return this.button.action(event);
        }

        this.disableButton();

        this.button.submit().then((response) => {
            this.enableButton();

            return this.button.afterSubmit(response);
        });
    }

    /**
     * Disables button
     */
    disableButton() {
        $(this.element).button('loading');
    }

    /**
     * Enables button
     */
    enableButton() {
        $(this.element).button('reset');
    }

}
