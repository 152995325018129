import { bindable, bindingMode, computedFrom } from 'aurelia-framework';

export class FormField {

    @bindable({defaultBindingMode: bindingMode.twoWay}) formId;
    @bindable({defaultBindingMode: bindingMode.twoWay}) element;
    @bindable({defaultBindingMode: bindingMode.twoWay}) value;

    viewModels = {
        'text':                           './components/form-input',
        'checkbox':                       './components/form-checkbox',
        'button':                         './components/form-input',
        'color':                          './components/form-input',
        'date':                           './components/form-input',
        'datetime':                       './components/form-input',
        'datetime-local':                 './components/form-input',
        'email':                          './components/form-input',
        'month':                          './components/form-input',
        'number':                         './components/form-input',
        'password':                       './components/form-input',
        'file-dropzone':                  './components/form-file-dropzone',
        'file':                           './components/form-file-dropzone',
        'range':                          './components/form-input',
        'search':                         './components/form-input',
        'tel':                            './components/form-input',
        'time':                           './components/form-input',
        'url':                            './components/form-input',
        'week':                           './components/form-input',
        'options':                        './components/form-select',
        'buttons':                        './components/form-actions',
        'undefined':                      './components/form-input',
        'null':                           './components/form-input',
        'int':                            './components/form-input',
        'integer':                        './components/form-input',
        'float':                          './components/form-input',
        'string':                         './components/form-input',
        'bool':                           './components/form-checkbox',
        'boolean':                        './components/form-checkbox',
        'actions':                        './components/form-actions',
        'textarea':                       './components/form-textarea',
        'select2':                        './components/form-select2',
        'multiselect-native':             './components/form-multiselect-native',
        'bootstrap-datetimepicker':       './components/form-bootstrap-datetimepicker',
        'daterangepicker':                './components/form-daterangepicker',
        'color-picker':                   './components/form-color-picker',
        'fancytree':                      './components/form-fancytree',
        'duallistbox':                    './components/form-duallistbox',
        'users-duallistbox':              './components/form-users-duallistbox',
        'tags':                           './components/form-tags',
        'summernote':                     './components/form-summernote',
        'labelsorter':                    './components/form-labelsorter',
        'users-select':                   './components/form-user-select2',
        'boolean-options-select':         './components/form-boolean-options-select2',
        'boolean-statuses-select':        './components/form-boolean-statuses-select2',
        'datetimepicker':                 './components/form-datetimepicker',
        'select2-native':                 './components/form-select2-native',
        'months-select':                  './components/form-months-select2',
        'years-select2':                  './components/form-years-select2',
        'smiley-rating':                  './components/form-smiley-rating',
        'int-checkbox':                   './components/form-int-checkbox',
        'material-ui-date-picker':        './components/form-material-ui-date-picker',
        'material-ui-time-picker':        './components/form-material-ui-time-picker',
        'material-ui-datetime-picker':    './components/form-material-ui-datetime-picker',
        'select2-icons':                  './components/form-select2-icons',
        'diploma-classifications-select': './components/form-diploma-classifications-select2',
    };

    /**
     * Constructor
     */
    constructor() {
        this.formField = this;
    }

    /**
     * Returns the string that points to the template file of that specific form
     * element type.
     *
     * @returns {string}
     */
    @computedFrom('element')
    get viewModel() {
        let viewModel = this.viewModels[this.element.type];

        if (typeof viewModel === 'undefined' || viewModel === null) {
            console.error('Element type is invalid');
        } else if (viewModel instanceof Object) {
            if (viewModel.condition()) {
                viewModel = viewModel.fallback;
            } else {
                viewModel = viewModel.name;
            }
        }

        return viewModel;
    }

}
