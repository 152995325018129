import { inject }        from 'aurelia-framework';
import { BaseComponent } from 'resources/elements/aurelia-form/components/base-component';
import { AppContainer }  from 'resources/services/app-container';

@inject(AppContainer)
export class FormCheckbox extends BaseComponent {

    /**
     * Returns is checked attribute
     *
     * @returns {boolean}
     */
    get isChecked() {
        return this.model.element.option.value === this.model.value;
    }

    /**
     * Creates element
     */
    createElement() {
        return this.simplePromise(() => {

            // initializes uniform on checkbox
            $('#' + this.modelElementId).uniform();

        });
    }

    /**
     * Destroys element
     */
    destroyElement() {
        return this.simplePromise(() => {

            // initializes uniform on checkbox
            $.uniform.update($('#' + this.modelElementId));

        });
    }

    /**
     * Handles changes event
     *
     * @param event
     */
    changed(event) {
        this.model.value = event.target.checked === true ? event.target.value : null;
    }
}
