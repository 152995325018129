import { PLATFORM } from 'aurelia-pal';

export function configure(config) {
    config.globalResources(
        [
            PLATFORM.moduleName('./components/form-actions'),
            PLATFORM.moduleName('./components/form-boolean-options-select2'),
            PLATFORM.moduleName('./components/form-boolean-statuses-select2'),
            PLATFORM.moduleName('./components/form-checkbox'),
            PLATFORM.moduleName('./components/form-duallistbox'),
            PLATFORM.moduleName('./components/form-diploma-classifications-select2'),
            PLATFORM.moduleName('./components/form-fancytree'),
            PLATFORM.moduleName('./components/form-file-dropzone'),
            PLATFORM.moduleName('./components/form-input'),
            PLATFORM.moduleName('./components/form-material-ui-date-picker'),
            PLATFORM.moduleName('./components/form-material-ui-datetime-picker'),
            PLATFORM.moduleName('./components/form-material-ui-time-picker'),
            PLATFORM.moduleName('./components/form-months-select2'),
            PLATFORM.moduleName('./components/form-multiselect-native'),
            PLATFORM.moduleName('./components/form-select2'),
            PLATFORM.moduleName('./components/form-select2-icons'),
            PLATFORM.moduleName('./components/form-tags'),
            PLATFORM.moduleName('./components/form-textarea'),
            PLATFORM.moduleName('./components/form-users-duallistbox'),
            PLATFORM.moduleName('./components/form-user-select2'),
            PLATFORM.moduleName('./form-alert'),
            PLATFORM.moduleName('./form-attributes'),
            PLATFORM.moduleName('./form-field'),
            PLATFORM.moduleName('./form-fields'),
            PLATFORM.moduleName('./form-group'),
            PLATFORM.moduleName('./schema-form'),
        ],
    );
}
