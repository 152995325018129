import { bindable, computedFrom, containerless, inject } from 'aurelia-framework';
import { AppContainer }                                  from 'resources/services/app-container';

@containerless
@inject(AppContainer)
export class FormGroup {

    observers = [];

    @bindable element;
    @bindable required;

    /**
     * Add here components that shall have fixed label
     *
     * @type {[]}
     */
    fixedLabelComponents = [
        'checkbox',
        'duallistbox',
        'multiselect-native',
        'file-dropzone',
        'smiley-rating',
        'summernote',
        'tags',
        'users-duallistbox',
    ];

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        this.appContainer = appContainer;
    }

    /**
     * Handles bind event
     */
    bind() {
        this.required = this.element.required !== null && typeof this.element.required !== 'undefined' ? this.element.required : true;

        this.subscribeObservers();
    }

    /**
     * Handles unbind event
     */
    unbind() {
        this.disposeObservers();
    }

    /**
     * Subscribes observers
     */
    subscribeObservers() {
        // subscribes `element.required` property change
        this.observers.push(
            this.appContainer
                .bindingEngine
                .propertyObserver(this.element, 'required')
                .subscribe(() => this.required = this.element.required),
        );
    }

    /**
     * Disposes observers
     */
    disposeObservers() {
        while (this.observers.length) {
            this.observers.pop().dispose();
        }

        this.observers.length = 0;
    }

    /**
     * Users can set label to false if they do not want to show the label
     *
     * @returns {boolean} true if label is not false
     */
    @computedFrom('element')
    get showsLabel() {
        return this.element.label !== false && this.element.showLabel !== false;
    }

    /**
     * Returns label class
     *
     * @returns {string}
     */
    @computedFrom('element')
    get labelClass() {
        return this.fixedLabelComponents.includes(this.element.type) ? 'display-block' : 'form-group-float-label control-label';
    }

}
